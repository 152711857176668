.board {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
}

@media only screen and (min-width: 480px) {
  .board {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
