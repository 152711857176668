.character-card-adder {
  display: flex;
  flex-direction: column;
}

.character-card-adder form {
  flex-grow: 1;
  display: grid;
  grid-template-rows: auto 1fr;
  align-content: space-between;
  justify-items: center;
  align-items: center;
}

.character-card-adder button {
  width: 72px;
  height: 72px;
  border: none;
  outline: none;
  border-radius: 36px;
  background-color: var(--bg-color-dark);
  color: var(--main-color);
  font-size: 18px;
}

.character-card-adder .select {
  width: 100%;
  height: 40px;
}

.character-card-adder .select select {
  padding: 0px 8px;
}
