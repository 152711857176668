.page-content-container {
  margin: 0px 16px;
  padding: 24px 0px;
}

@media only screen and (min-width: 768px) {
  .page-content-container {
    margin: 0px 80px;
  }
}

@media only screen and (min-width: 1200px) {
  .page-content-container {
    margin: 0px 160px;
  }
}

.page-block {
  box-sizing: border-box;
  padding: 80px 0px;
  min-height: calc(100vh - 64px - 96px);
}
