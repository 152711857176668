.summary-card {
  position: relative;
  background-color: var(--bg-color-light);
  border-radius: 4px;
  padding: 18px 14px;
  border: 4px solid var(--bg-color-dark);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.4);
  transition: 0.1s;
  user-select: none;
}

@media only screen and (min-width: 480px) {
  .summary-card {
    width: 256px;
  }
}

.summary-card:hover {
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.4);
}

.summary-card h1 {
  margin: 0px;
  font-size: 24px;
}

.summary-card h2 {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
}

/* .summary-card-complete {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: var(--bg-color);
  opacity: 0.9;
  transition: 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.summary-card-complete {
  width: 28px;
  height: 28px;
  background-color: var(--bg-color-light);
  border: 3px solid var(--bg-color-dark);
  border-radius: 4px;
  position: absolute;
  top: -10px;
  right: -10px;

  color: var(--main-color);
  font-size: 12px;

  cursor: pointer;
}

.summary-card-complete p {
  font-size: 18px;
}

.summary-card-header {
  margin-bottom: 2px;

  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.summary-card-costs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 2px;
}

.summary-card-body {
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 4px;
}
