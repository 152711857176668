* {
  margin: 0px;
  padding: 0px;
}

body {
  background-color: var(--bg-color);
  color: var(--main-color);
  font-family: 'Montserrat', sans-serif;
}

.App {
  min-height: calc(100vh - 64px - 96px);
  position: relative;
  padding-top: 64px;
  padding-bottom: 96px;
}
