:root {
  /* --main-color: #d3bc8e;
  --main-color-filter: invert(81%) sepia(40%) saturate(337%) hue-rotate(344deg) brightness(91%) contrast(90%);

  --bg-color: #1c1c22;
  --bg-color-dark: #17171c;
  --bg-color-dark-transparent: #17171cbf;
  --bg-color-dark-filter: invert(7%) sepia(12%) saturate(753%) hue-rotate(201deg) brightness(96%);
  --bg-color-light: #202027; */

  --main-color: #5f7586;
  --main-color-transparent: #5f7586bf;
  --main-color-filter: invert(47%) sepia(18%) saturate(545%) hue-rotate(164deg) brightness(89%) contrast(87%);

  --bg-color: #e1e1d6;
  --bg-color-dark: #d8d8ca;
  --bg-color-dark-transparent: #d8d8cabf;
  --bg-color-dark-filter: invert(5%) sepia(12%) saturate(1142%) hue-rotate(201deg) brightness(88%) contrast(91%);
  --bg-color-light: #e7e7df;

  --shadow-color: #0000007f;
}

/* .one-star {
  background-color: #8c8c8c5f;
}

.two-star {
  background-color: #6aaf8c5f;
}

.three-star {
  background-color: #6a92af5f;
}

.four-star {
  background-color: #8875a35f;
}

.five-star {
  background-color: #b493645f;
} */
