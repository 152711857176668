.toggle-switch {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;

  user-select: none;
}

.toggle-switch input {
  display: none;
}

.toggle-switch input:checked + .slider {
  background-color: var(--main-color);
}

.toggle-switch input:checked + .slider:before {
  transform: translateX(24px);
}

.toggle-switch p {
  height: 24px;
  padding: 0px 12px;
  border-radius: 4px;
  background-color: var(--bg-color-dark);

  display: flex;
  align-items: center;
}

.slider {
  position: relative;
  width: 48px;
  height: 24px;
  border-radius: 12px;
  background-color: var(--bg-color-dark);

  transition: var(--animation-speed);
}

.slider:before {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 3px;
  left: 3px;
  border-radius: 10px;
  background-color: var(--bg-color-light);

  content: "";
  transition: var(--animation-speed);
}
