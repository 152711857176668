.cost-display {
  min-height: 24px;
  padding: 0px 8px;
  background-color: var(--bg-color-dark);
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.cost-display img {
  height: 14px;
  margin-right: 4px;
}
