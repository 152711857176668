.card {
  box-sizing: border-box;
  padding: 18px 14px;
  border: 4px solid var(--bg-color-dark);
  border-radius: 4px;
  background-color: var(--bg-color-light);
  box-shadow: 0px 3px 6px 0px var(--shadow-color);
}

.card h2 {
  margin-bottom: 2px;
}

.card h3 {
  margin-top: 20px;
  margin-bottom: 4px;
}

.card:hover {
  box-shadow: 0px 6px 12px 0px var(--shadow-color);
}

.character-card {
  width: 288px;
  height: 448px;
}

.weapon-card {
  width: 288px;
  height: 320px;
}

.input-card-header {
  margin-bottom: 4px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.input-card-header img {
  width: 42px;
  height: 42px;
  border: 3px solid var(--bg-color-dark);
  border-radius: 4px;
  background-color: var(--bg-color);
}

.input-card-header div {
  width: 100%;
  overflow: hidden;
}

/* .input-card-header h2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
} */

.input-card-header h2 {
  margin: 0px;
  width: 100%;
  max-height: 64px;
  
  font-size: 28px;
  font-weight: bold;
  /* text-align: center; */

  word-wrap: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-card-header button {
  margin: 0;
  border: none;
  background: none;
  color: var(--main-color);
  font-size: 18px;
  cursor: pointer;
  text-align: center;
}

/* .summary-card {

} */
