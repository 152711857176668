.day-picker {
  width: 280px;
  height: 60px;
  background-color: var(--bg-color-dark);
  border-radius: 4px;
  display: flex;
}

.day-picker-day {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.day-picker-day p {
  font-size: 16px;
  line-height: 10px;
}
