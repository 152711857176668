.summary-page .summary-filter {
  margin-top: 16px;
}

.summary-page .summary-nav-buttons {
  margin-top: 32px;
}

.summary-page .summary-board {
  margin-top: 8px;
}
