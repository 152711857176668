.item-display {
  background-color: var(--bg-color-dark);
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: initial;
}

.item-display img {
  max-width: 85%;
  max-height: 85%;
  pointer-events: none;
}

.item-amount {
  padding: 1px 3px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: var(--bg-color-dark-transparent);
  border-radius: 4px;
  pointer-events: none;
}

.item-tooltip {
  padding: 2px 6px;
  background-color: var(--bg-color-light);
  visibility: hidden;
  opacity: 0;
  border: 2px solid var(--bg-color-dark);
  border-radius: 4px;
  font-size: 14px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 1;
  top: 32px;
  left: 8px;
  pointer-events: none;
  transition: 0.1s;
}

.item-display:hover .item-tooltip {
  visibility: visible;
  opacity: 1;
}

.item-complete {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color-dark-transparent);
  border-radius: 4px;

  font-size: 24px;

  display: flex;
  justify-content: center;
  align-items: center;
}
