.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--bg-color-dark);
  box-shadow: 0px -2px 4px 4px var(--shadow-color);
  z-index: 99;
}

.navbar-main {
  position: relative;
  min-height: 64px;
}

.navbar-contents {
  /* position: relative; */
  border-top: 1px solid var(--main-color);
  list-style: none;
}

.navbar-contents li {
  min-height: 64px;
  text-align: center;
  line-height: 64px;
}

.navbar-toggle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  cursor: pointer;
  font-size: 24px;
}

.navbar-logo {
  padding: 0px 6px;
  border: 2px solid var(--main-color);
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  font-size: 16px;
}

@media only screen and (min-width: 768px) {
  .navbar-toggle {
    right: 80px;
  }
  
  .navbar-logo {
    left: 80px;
  }
}

@media only screen and (min-width: 1200px) {
  .navbar-toggle {
    right: 160px;
  }
  
  .navbar-logo {
    left: 160px;
  }
}

.navbar-links {
  font-size: 18px;
}

.navbar-logo, .navbar-links {
  font-family: 'Montserrat', sans-serif;
  /* font-size: 16px; */
  color: var(--main-color);
  text-decoration: none;
  text-transform: uppercase;
}

.navbar-shadow {
  position: absolute;
  bottom: -100vh;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--shadow-color);
}
