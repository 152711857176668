.summary-group-header {
  width: 100%;
  background-color: var(--bg-color-dark);
  border-radius: 4px;
  /* overflow: hidden; */

  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 480px) {
  .summary-group-header {
    width: 292px;
  }
}

.summary-group-header-main {
  min-height: 80px;
  padding-left: 8px;
  padding-right: 16px;
  /* padding-top: 8px;
  padding-bottom: 8px; */
  border-radius: 4px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.summary-group-header-main img {
  background-color: var(--bg-color-light);
  border-radius: 4px;
}

.summary-group-header-main h2 {
  position: relative;
  margin: 0px;
  /* width: 100%; */
  max-height: 80px;
  
  font-size: 28px;
  font-weight: bold;
  text-align: center;

  word-wrap: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.summary-group-header-main i {
  font-size: 18px;
}

.summary-group-header-main i:active {
  transform: rotate(90deg)
}

.summary-group-header-options {
  padding: 16px 14px;
  background-color: var(--bg-color-light);
  border: 4px solid var(--bg-color-dark);
  gap: 12px;
}

.summary-group-header-options h3 {
  margin-bottom: 4px;
  font-size: 16px;
}
