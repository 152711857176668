.card-back {
  min-height: 360px;
  width: 240px;
  background-color: var(--bg-color-light);
  border-radius: 4px;
}

.summary-card-back {
  width: 256px;
  background-color: var(--bg-color-light);
  border-radius: 4px;
}

.card-front {
  padding: 18px 14px;
  background-color: var(--bg-color-light);
  border-radius: 4px;
  border: 4px solid var(--bg-color-dark);
  /* opacity: 0.5; */
  box-shadow: 0px 3px 6px 0px var(--shadow-color);
  transition: 0.1s;
  flex: 1;
  user-select: none;
}

.card-front:hover {
  box-shadow: 0px 6px 12px 0px var(--shadow-color);
}

.card-front h2 {
  margin-bottom: 2px;
}

.card-front h3 {
  margin-top: 20px;
  margin-bottom: 4px;
}

.card-front hr {
  height: 2px;
  border: none;
  background: var(--main-color);
  margin-bottom: 16px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.card-header button {
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  background: none;
  color: var(--main-color);
  font-size: 18px;
  cursor: pointer;
  text-align: center;
}

