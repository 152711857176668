.summary-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
  user-select: none;
}

.summary-group hr {
  border: none;
}

.summary-card-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
}

@media only screen and (min-width: 480px) {
  .summary-card-container {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
  }
}

.summary-progression-arrow {
  display: none;
}

@media only screen and (min-width: 480px) {
  .summary-progression-arrow {
    display: flex;
    align-items: center;
    font-size: 18px;
  }
}

.summary-progression-arrow-mobile {
  display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

@media only screen and (min-width: 480px) {
  .summary-progression-arrow-mobile {
    display: none;
  }
}
