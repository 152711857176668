.home-page p {
  text-align: center;
}

.home-page .page-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-page .page-block:first-of-type h1 {
  text-align: center;
}

.home-page .page-block:first-of-type p {
  margin-bottom: 16px;
}

.home-page .page-block:nth-of-type(2) .column {
  align-items: center;
  gap: 48px;
}

.home-page .row {
  justify-content: center;
}

.genshin-planner-logo {
  filter: var(--main-color-filter);
}
