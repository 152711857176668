.tooltip {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-text {
  position: absolute;
  top: 32px;
  left: 8px;
  z-index: 1;
  padding: 2px 6px;
  border: 2px solid var(--bg-color-dark);
  border-radius: 4px;
  background-color: var(--bg-color-light);

  visibility: hidden;
  opacity: 0;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
  font-size: 14px;
  
  pointer-events: none;
  transition: 0.1s;
}
