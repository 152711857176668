.checkbox {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;

  user-select: none;
}

.checkbox input {
  display: none;
}

.checkbox input:checked + .box {
  background-color: var(--main-color);
}

.checkbox input:checked + .box i {
  opacity: 1;
}

.checkbox p {
  height: 24px;
  padding: 0px 12px;
  border-radius: 4px;
  background-color: var(--bg-color-dark);

  display: flex;
  align-items: center;
}

.box {
  width: 12px;
  height: 12px;
  border: 1px solid var(--main-color);
  border-radius: 2px;
  background-color: var(--bg-color-dark);

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 10px;
  color: var(--bg-color-dark);

  transition: var(--animation-speed);
}

.box i {
  opacity: 0;

  transition: var(--animation-speed);
}
