/* .footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 48px;
  background-color: var(--bg-color-dark);
  padding: 24px 0px;
  text-align: center;
  font-size: 12px;
} */

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 96px;
  background-color: var(--bg-color-dark);
  font-size: 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
