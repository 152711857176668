.select {
  position: relative;
  width: 48px;
  height: 28px;
}

.select select {
  width: 100%;
  height: 100%;
  padding: 0px 6px;
  background-color: var(--bg-color-dark);
  color: var(--main-color);
  border: none;
  border-radius: 4px;

  cursor: pointer;
  appearance: none;
}

.select i {
  position: absolute;
  top: calc(50% - 7px);
  right: 6px;

  font-size: 14px;

  pointer-events: none;
}
