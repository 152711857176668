.ascension-indicator {
  display: flex;
  column-gap: 4px;
}

.ascension-indicator-star {
  height: 28px;
  width: 28px;
}

.ascension-indicator-star-active {
  /* filter: invert(81%) sepia(40%) saturate(337%) hue-rotate(344deg) brightness(91%) contrast(90%); */
  filter: var(--main-color-filter);
}

.ascension-indicator-star-inactive {
  /* filter: invert(7%) sepia(12%) saturate(753%) hue-rotate(201deg) brightness(96%); */
  filter: var(--bg-color-dark-filter);
}
