.character-card-header-entry {
  display: flex;
  align-items: center;
  gap: 12px;
}

.character-card-portrait {
  width: 42px;
  height: 42px;
  border-radius: 4px;
  border: 3px solid var(--bg-color-dark);
  background-color: var(--bg-color);
  overflow: hidden;
}

.character-card-portrait img {
  width: 100%;
  height: 100%;
}

.character-card-back {
  width: 288px;
  min-height: 448px;
  display: flex;
  align-items: stretch;
}

.black-character-card {
  min-width: calc(288px - 8px);
  min-height: calc(448px - 8px);
  border-radius: 4px;
  background-color: var(--bg-color-light);
  padding: 4px;
  display: flex;
  align-items: stretch;
}
