h1 {
  margin-bottom: 16px;
  font-size: 42px;
}

h2 {
  margin-bottom: 14px;
  font-size: 28px;
}

h3 {
  margin-bottom: 12px;
  font-size: 16px;
}

hr {
  height: 2px;
  border: none;
  background: var(--main-color);
  margin-bottom: 16px;
}

p {
  font-size: 14px;
}

p a {
  color: inherit;
  /* text-decoration: none; */
}

.capitalize {
  text-transform: capitalize;
}
