.button {
  height: 40px;
  padding: 0px 16px;
  background-color: var(--bg-color-dark);
  border: none;
  border-radius: 4px;

  color: var(--main-color);
  font-family: inherit;
  font-size: 14px;
  /* text-decoration: none; */
}
