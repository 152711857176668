.weapon-card-thumbnail {
  width: 42px;
  height: 42px;
  border: 3px solid var(--bg-color-dark);
  border-radius: 4px;
  background-color: var(--bg-color);
}

.weapon-card-text {
  width: 175px;
}
