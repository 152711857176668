.summary-board {
  display: flex;
  width: 100%;

  flex-direction: column;
  gap: 32px;
}

.summary-board-no-content {
  width: calc(100% - 128px);
  height: 240px;
  padding: 0px 64px;
  background-color: var(--bg-color-light);
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
}
