.row {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.row-4 > * + * {
  margin-left: 4px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}

hr.hr-fade {
  margin: 0px;
  height: 1px;
  border: 0px;
  background: none;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    var(--main-color),
    rgba(0, 0, 0, 0)
  );
}

hr.hr-fade-thick {
  margin: 0px;
  height: 2px;
  border: 0px;
  background: none;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    var(--main-color),
    rgba(0, 0, 0, 0)
  );
}

hr.hr-invisible {
  height: 0px;
}
